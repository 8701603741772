<script setup>
const children = useSlots().default?.() || []

const {onWindowEvent} = useWindowEvents();
const {lte} = useBreakpoints();

const activeAnchor = ref("");

onWindowEvent('scroll', (e) => {
  const anchors = document.querySelectorAll("a[id]");
  let currentAnchorVisible = "";
  for(let anchor of anchors) {
    const { top } = anchor.getBoundingClientRect();
    if(top <= (lte('md') ? 155 : 185)) {
      currentAnchorVisible = anchor.id;
    }
  }
  activeAnchor.value = currentAnchorVisible;
})
</script>

<template>
  <div class="font-extralight text-sm whitespace-nowrap overflow-hidden -mx-4 pl-4 overflow-no-scrollbars">
    <div class="flex items-center h-10">
      <Component v-for="childComponent in children" :is="childComponent" :activeAnchor="activeAnchor" class="mr-3"/>
    </div>
  </div>
</template>
