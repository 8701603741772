<script setup>
const props = defineProps({
  to: {
    type: String,
    default: ""
  },
  activeAnchor: String
})
const {lte} = useBreakpoints();
const offset = computed(() =>   lte('md') ? 150 : 180);

const jump = () => {
  if (!props.to) {
    window.scrollTo({top: 0, behavior: "smooth"});
    return;
  }
  const target = document.querySelector(`#${props.to}`);
  if (!target) return;
  const top = target.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset.value;
  window.scrollTo({top: top, behavior: "smooth"});
}

</script>

<template>
  <div class="cursor-pointer transition-colors" :class="{'text-karmin font-normal': activeAnchor === props.to}" @click="jump">
    <slot />
  </div>
</template>
